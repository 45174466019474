module.exports = {
  siteTitle: 'Arpit Solanki | Data Engineer',
  siteDescription:
    'Arpit is a Data Engineer based out of Delhi who specialises in Data engineering, building scalable data products.',
  siteKeywords:
    'Arpit Solanki, arpit1997, solankiarpit191, Arpit, solankiarpit1997@gmail.com, data engineer, mentor',
  siteUrl: 'https://arpitsolanki.in',
  siteLanguage: 'en_US',

  googleVerification: '',

  name: 'Arpit Solanki',
  location: 'New Delhi, IN',
  email: 'solankiarpit1997@gmail.com',
  github: 'https://github.com/arpit1997/',
  socialMedia: [
    {
      name: 'Github',
      url: 'https://github.com/arpit1997/',
    },
    {
      name: 'Linkedin',
      url: 'https://www.linkedin.com/in/arpit-solanki/',
    },
    {
      name: 'Stackoverflow',
      url: 'https://stackoverflow.com/users/story/5250746',
    },
    {
      name: 'Codementor',
      url: 'https://www.codementor.io/arpitsolanki',
    },
    {
      name: 'Twitter',
      url: 'https://twitter.com/solankiarpit191',
    },
  ],

  navLinks: [
    {
      name: 'About',
      url: '#about',
    },
    {
      name: 'Experience',
      url: '#jobs',
    },
    {
      name: 'Work',
      url: '#projects',
    },
    {
      name: 'Contact',
      url: '#contact',
    },
  ],

  twitterHandle: '@solankiarpit191',
  googleAnalyticsID: '',

  navHeight: 100,

  greenColor: '#64ffda',
  navyColor: '#0a192f',
  darkNavyColor: '#020c1b',

  srConfig: (delay = 200) => ({
    origin: 'bottom',
    distance: '20px',
    duration: 500,
    delay,
    rotate: { x: 0, y: 0, z: 0 },
    opacity: 0,
    scale: 1,
    easing: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
    mobile: true,
    reset: false,
    useDelay: 'always',
    viewFactor: 0.25,
    viewOffset: { top: 0, right: 0, bottom: 0, left: 0 },
  }),
};
