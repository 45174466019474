import React from 'react';

const IconCodementor = () => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="225.000000pt"
    height="225.000000pt"
    viewBox="0 0 225.000000 225.000000"
    preserveAspectRatio="xMidYMid meet">
    <g transform="translate(0.000000,225.000000) scale(0.100000,-0.100000)">
      <path
        d="M0 1125 l0 -1125 1125 0 1125 0 0 1125 0 1125 -1125 0 -1125 0 0
-1125z m1725 450 l25 -24 0 -391 0 -390 40 0 40 0 0 -44 c0 -82 52 -76 -703
-76 -370 0 -678 4 -685 8 -15 10 -29 60 -24 90 3 18 10 22 43 22 l39 0 0 390
0 391 25 24 24 25 576 0 576 0 24 -25z"
      />
      <path
        d="M590 1145 l0 -375 203 0 c191 0 203 -1 215 -20 11 -18 23 -20 117
-20 94 0 105 2 115 20 10 19 21 20 215 20 l205 0 0 375 0 375 -535 0 -535 0 0
-375z m807 251 c28 -30 33 -43 33 -86 0 -72 -48 -120 -120 -120 -43 0 -56 5
-85 33 -43 39 -54 87 -32 140 36 84 141 102 204 33z m-336 14 c21 -12 25 -50
7 -68 -8 -8 -61 -12 -173 -12 -112 0 -165 4 -173 12 -18 18 -15 55 6 67 23 14
308 14 333 1z m14 -190 l0 -35 -178 -3 c-193 -3 -197 -2 -183 53 l6 25 178 -2
177 -3 0 -35z m217 -68 c21 -2 52 1 69 7 27 9 36 8 63 -11 44 -32 62 -55 96
-125 39 -78 42 -129 11 -155 -21 -16 -44 -18 -227 -18 -194 0 -205 1 -225 21
-28 28 -21 81 24 169 36 71 107 137 136 124 9 -4 33 -9 53 -12z m-311 -52 c21
-12 25 -50 7 -68 -17 -17 -249 -17 -266 0 -15 15 -15 51 0 66 15 15 231 16
259 2z m-27 -172 c8 -13 8 -23 0 -35 -9 -15 -29 -18 -122 -21 l-111 -3 -7 26
c-13 52 -4 56 117 53 94 -3 114 -6 123 -20z"
      />
    </g>
  </svg>
);

export default IconCodementor;
